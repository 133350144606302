import {post, get, ResponseType, URL} from "@/utils/axios";

import {ConversionContent} from '@/utils/tool'
import {
    AUDIT_CONFIG_MAP,
    AUDIT_REVOKE_RECORD,
    EXPORT_AUDIT_COUNT, RE_AUDIT_BATCH_FIRST_PASS,
    RE_AUDIT_BATCH_NO_OBJECTION, REAL_AUDIT_COUNT,
    SOURCE_TYPE,
    UPDATE_AUDITOR
} from "@/utils/api/url";

export const getAuditChapterContentApi = async (auditId: number) => {
    const {code, data, msg} = await get(URL.AUDIT_CHAPTER_CONTENT, {params: {auditId}});
    if (code === 1) return {code, data, msg};
    // data = res.data
    const {
        chapterContent = '',
        characterDTOList = []
    } = data?.chapterVO ?? {};
    const content = ConversionContent(chapterContent, characterDTOList, 'id');

    return {...data, content, msg, code}
};

export const getRejectReasonSetApi = async (sourceType: string) => get(URL.AUDIT_REJECT_REASON_SET, {params: {sourceType}});
export const getMachineAuditRejectReason = () => get(URL.AUDIT_MACHINE_REJECT_REASON_SET)
export const getConfigMapApi = (sourceType: string) => get(URL.AUDIT_CONFIG_MAP,{params: {sourceType}});

// 通过
export const adoptApi = (auditId: number, sourceType: string) => {
    return post(`${URL.AUDIT_FIRST_PASS}/${sourceType}`, {data: {auditId}})
};

export const undeterminedApi = (auditId: number, sourceType: string) => {
    return post(`${URL.AUDIT_FIRST_UNDETEMINED}/${sourceType}`, {data: {auditId}})
};

// 忽略
export const ignoreApi = (auditId: number) => {
    return post(URL.AUDIT_IGNORE, {data: {auditId}})
};


interface RejectApiParams {
    rejectReason: string
    auditId: number,
}

// 拒绝
export const rejectApi = (data: RejectApiParams, sourceType: string) => {
    return post(`${URL.AUDIT_FIRST_REJECT}/${sourceType}`, {data});
}

// 屏蔽图片
export const shieldImgApi = (imageUri: string) => {
    return post(URL.AUDIT_REJECT_IMAGE, {data: {imageUri}})
}


// 获取复审筛选项
// export const


export interface AuditListReq {
    page: number
    size: number
    // againAuditorId: string

    firstAuditorId?: string
    sourceType?: string
    personAuditStatus?: string
    rejectFirstReason?: string
    riskLevel?: string

    sourceId?: string
    createTimeStart?: string
    createTimeEnd?: string
    personFirstTimeStart?: string
    personFirstTimeEnd?: string
}

//批量初审通过
export const batchFirstPassApi = (auditIds: number | number[], sourceType: string) => {
    return post(`${URL.RE_AUDIT_BATCH_FIRST_PASS}/${sourceType}`, {data: {auditIds}})
}


// 获取初审数据
export const getAuditListApi = (params: { sourceType: string, size: number }) => {
    return get(`${URL.AUDIT_LIST}${params.sourceType}`, {params})
}

// 获取复审数据
export const getReAuditListApi = (params: AuditListReq) => {
    return get(URL.RE_AUDIT_LIST, {params})
}
// 获取复审数据
export const getReAudit = (params: { auditId: number, sourceType: string }) => {
    return get(URL.RE_AUDIT_GET, {params})
}

// 获取复审配置
export const getQueryConfigApi = (sourceType: string) => {
    return get(URL.RE_AUDIT_GET_QUERY_CONFIG, {params: {sourceType}})
}
export const blockContent = () => {

}

// 复审通过
export const ReAuditPassApi = (auditId: number) => {
    return post(URL.RE_AUDIT_AGAIN_PASS, {data: {auditId}})
}
//复审无异议
export const ReAuditNoObjectionApi = (auditId: number) => {
    return post(URL.RE_AUDIT_AGAIN_NO_OBJECTION, {data: {auditId}})
}
//批量复审通过
export const batchReAuditPassApi = (auditIds: number | number[]) => {
    return post(URL.RE_AUDIT_BATCH_AGAIN_PASS, {data: {auditIds}})
}

//批量无异议
export const batchReAuditNoObjectionApi = (auditIds: number | number[]) => {
    return post(URL.RE_AUDIT_BATCH_NO_OBJECTION, {data: {auditIds}})
}
// 复审拒绝
export const ReAuditRejectApi = (data: { auditId: number, rejectReason: string }) => {
    return post(URL.RE_AUDIT_AGAIN_REJECT, {data})
}
// 复审待定
export const ReAuditUndeterminedApi = (auditId: number) => {
    return post(URL.RE_AUDIT_AGAIN_UNDETERMINED, {data: {auditId}})
}
// 获取安审量
export const getAuditCount = (params: { day: string }) => {
    return get(URL.AUDIT_COUNT, {params})
}
// 获取审核权限
export const getAuditPermissionsList = (params: {}) => {
    return get(URL.AUDIT_PERMISSIONS_LIST, {params})
}
// 获取审核员列表
export const getAuditorList = (params: {
    pageNo?: number
    pageSize?: number
    id?: number
    nickname?: string
    email?: string
    mobile?: string
    status?: DisableStatusType
}) => {
    return get(URL.AUDITOR_LIST, {params})
}
// 获取审核员列表
export const updateAuditor = (data: {
    userId: number;
    mobile: string;
    remark: string;
    workTime: number;
    roleIdList: [number];
    isNewbie?: boolean
}) => {
    return post(URL.UPDATE_AUDITOR, {data})
}
// 导出审核量
export const exportAuditCount = (params: {
    startDay: string;
    endDay: string;
}) => {
    return get(URL.EXPORT_AUDIT_COUNT, {params})
}// 可撤回的审核记录
export const getAuditRevokeRecord = (params: {
    sourceType: string;
}) => {
    return get(URL.AUDIT_REVOKE_RECORD, {params})
}// 审核记录
export const getAuditRecord = (params: {
    page: number;
    size: number;
    sourceType: string;
    auditTimeStart: string;
    auditTimeEnd: string;
}) => {
    return get(URL.AUDIT_RECORD, {params})
}
/**
 * 获取审核类型
 * @param params
 */
export const getSourceType = (params: {}) => {
    return get(URL.SOURCE_TYPE, {params})
}
/**
 * 获取审核量
 */
export const getRealAuditCount = () => {
    return get(URL.REAL_AUDIT_COUNT, {})
}

